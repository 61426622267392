.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.GoPages {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 20px;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 977px) {
  .GoPages {
    padding-top: 5px;
  }
}

.GoPages a,
.GoPages .logout {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  color: var(--gray-color);
  text-decoration: none;
  gap: 7px;
  transition: var(--main-transition);
  font-size: 20px;
}

@media (max-width: 977px) {
  .GoPages a,
  .GoPages .logout {
    padding: 5px;
  }
}

.GoPages a.active,
.GoPages a:hover,
.GoPages .logout:hover,
.GoPages .logout:active {
  color: var(--main-color);
  transition: 0.5s;
  font-weight: bold;
  box-shadow: 0 1px 0 0 var(--gray-color);
}
.GoPages a svg,
.GoPages .logout svg {
  color: var(--main-color);
}
.GoPages a .name,
.GoPages .logout .name {
  font-size: 14px;
  text-align: center;
}

@media (max-width: 977px) {
  .GoPages {
    gap: 5px;
  }

  .GoPages a,
  .GoPages .logout {
    justify-content: center;
    flex-direction: column;
    gap: 2px;
    padding: 5px;
    font-size: 13px;
  }

  .GoPages a.active,
  .GoPages .logout.active {
    box-shadow: none;
    color: var(--main-color);
  }

  .GoPages a svg,
  .GoPages .logout svg {
    font-size: 20px;
  }
  .GoPages a .name,
  .GoPages .logout .name {
    font-size: 11px;
  }
}
.logout {
  cursor: pointer;
}
