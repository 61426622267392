.container {
  min-height:100vh ;
  padding-top: 70px;
  position: relative;
  z-index: 1;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../dist/img/stars.png");
  opacity: 0.5;
  z-index: -1;
}
.info {
  width: 100%;
  /* height: 50vh; */
  border-radius: 16px;
   background-color: rgba(255, 255, 255, 0.082);
  box-shadow: var(--main-box-shadow);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* position: relative; */
  /* overflow: hidden; */
  padding: 10px;
  border: 1px solid var(--border-dark-bluer);
}

.info .img{
  display: flex;
  align-items: center;
  justify-content: center;
}

.info .img img{
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.infoCount{
  font-size: 16px;
  border-radius: 5rem;
  padding: 0;
  padding-left: 10px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  padding-right: 10%;
  font-weight: bold;
}
.infoCount span{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 100%;
  right: 0;
  transform: translateY(-50%);
}
.tit_dis{
  background-color: var(--bg-dark-bluer);
  border-radius: 12px;
  border: 1px solid var(--border-dark-bluer);
 padding: 10px;
 /* box-shadow: var(--main-box-shadow); */
}
.tit_dis h3{
  /* color: var(--main-color); */
  font-weight: bold;
}
.tit_dis p{
  margin-top: 15px;
  color: var(--gray-color);
  font-size: 14px;
  line-height: 1.6;
}

