
.container {
  min-height:100vh ;
  padding: 70px 0;
  position: relative;
  z-index: 1;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../dist/img/stars.png");
  opacity: 0.5;
  z-index: -1;
}