.container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000; /* أعلى قيمة z-index */
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
}

.body {
  max-width: 90vw;
  min-width: 320px;
  max-height: 95vh;
  background-color: var(--light-color);
  position: relative;
  border-radius: 16px;
  padding: 10px;
}

.hight {
  max-height: 90vh;
  overflow-x: auto;
}

.btn {
  position: absolute;
  top: -15px;
  right: -15px;
}

.btn svg {
  color: #fff !important;
}
