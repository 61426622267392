.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.img {
  width: 100%;
  height: 50vh; /* استخدم vh للحصول على ارتفاع بنسبة مئوية للشاشة */
  background-image: url("../../dist/img/404.gif");
  background-size: contain; /* يجعل الصورة تتناسب بالكامل مع العنصر */
  background-position: center;
  background-repeat: no-repeat;
}

.message {
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2em;
}

.message:after {
  content: "]";
}

.message:before {
  content: "[";
}

@media (max-width: 768px) {
  .img {
    max-width: 400px;
  }

  .message {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .img {
    max-width: 300px;
  }

  .message {
    font-size: 0.8em;
  }
}
