.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.GoPages {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;
  /* gap: 5px; */
  width: 100%;
}

.GoPages a,
.GoPages .logout,
.dropstart {
  width: 100%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: var(--gray-color);
  text-decoration: none;
  gap: 7px;
  transition: var(--main-transition);
  font-size: 20px;
}

.GoPages a.active,
.GoPages a:hover,
.GoPages .logout.active,
.GoPages .logout:hover,
.dropstart.active,
.dropstart:hover {
  color: var(--main-color);
  transition: 0.5s;
  font-weight: bold;
  box-shadow: 0 1px 0 0 var(--gray-color);
}

.dropdownMenu {
  background-color: var(--light2-color);
}
.dropdownMenu li {
  text-align: center;
}
.dropdownMenu li a span {
  width: 100%;
}

.GoPages a.active svg,
.dropstart:active svg {
  color: var(--main-color);
}

.GoPages a .name,
.GoPages .logout .name,
.dropstart .name {
  font-size: 14px;
  text-align: center;
}

@media (max-width: 977px) {
  .GoPages {
    gap: 5px;
  }

  .GoPages a,
  .GoPages .logout,
  .dropstart {
    justify-content: center;
    flex-direction: column;
    gap: 2px;
    padding: 5px;
    font-size: 13px;
  }

  .GoPages a.active,
  .GoPages .logout.active,
  .dropstart.active {
    box-shadow: none;
    color: var(--main-color);
  }

  .GoPages a svg,
  .GoPages .logout svg,
  .dropstart svg {
    font-size: 20px;
  }
  .GoPages a .name,
  .GoPages .logout .name,
  .dropstart .name {
    font-size: 11px;
  }
}

.lastTime {
  width: 100%;
}
