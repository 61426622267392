.inputWidth30{
  width:33.33% !important;
}
.inputWidth50{
  width:50% !important;
}

@media (max-width: 960px) {
.inputWidth30,.inputWidth50{
  width:100% !important;
}
}
.containerSwitch {
  margin-top: 0;
}
.containerSwitch > span {
  display: block;
  margin-bottom: 5px;
  text-align: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light2-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
  border: 1px solid var(--main-color);
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 2px;
  background-color: var(--main-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--light-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #5f5f5f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
