

.labelMode {
  position: relative;
  display: block;
  width: 65px;
  height: 32px;
  border-radius: 20px;
  background-color: var(--light-color);
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s ease;
  box-shadow: inset 0 0 3px 0 #f54a06ef;
}

.labelMode:before,
.labelMode:after {
  display: block;
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  transition: 0.5s ease;
}

.labelMode:before {
  background-color: #ffa41b;
  left: 7px;
  box-shadow: 0px 0px 4px 2px #f54a06ef;
}

.labelMode:after {
  background-color: var(--light-color);
  left: -6px;
  transform: scale(0.00001);
}

.inputMode[type="checkbox"] {
  display: none;
}

.inputMode[type="checkbox"]:checked + .labelMode:before {
  /* box-shadow: 2px 0px 6px 0px #ffffffef; */
  box-shadow: none;
  background-color: #fff;
  transform: translateX(26px);
}

.inputMode[type="checkbox"]:checked + .labelMode {
  box-shadow: inset 0 0 5px 0 #fefefe;
}

.inputMode[type="checkbox"]:checked + .labelMode:after {
  transform: translateX(30px) scale(1);
}
@media(max-width:977px){
.labelMode {
  width: 50px;
  height: 26px;
}
.labelMode:before,
.labelMode:after {
  width: 18px;
  height: 18px;
  top: 4px;
  left: 5px;
}
.labelMode:before {
  left: 5px;
}
.labelMode:after {
  left: -11px;
}
.inputMode[type="checkbox"]:checked + .labelMode:before {
  transform: translateX(22px);
}
}