.cardForm {
  /* max-width: 320px; */
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
}

.buttons {
  background-image: linear-gradient(#00000000, #0000003f, #0000008e, #000000);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardItemBg {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: var(--main-box-shadow);
}

.cardItem {
  width: calc(100% - 30px);
  position: relative;
  border-radius: 16px;
  margin-bottom: -90px;
  z-index: 10;
  box-shadow: 0px 8px 7px 0px rgb(97 97 97 / 29%);
  transition: 0.3s;
}
.price {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
}
.price span {
  color: #fff;
  text-align: center;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  padding: 2px;
  background: #ff4136;
  background: linear-gradient(#f70505 0%, #ff4136 100%);
  box-shadow: 0 3px 10px -5px #000;
  position: absolute;
  top: 19px;
  left: -21px;
  font-weight: 700;
  line-height: 20px;
}

.price span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 99%;
  z-index: -1;
  border-right: 3px solid #ff4136;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff4136;
}
.price ::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #ff4136;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff4136;
}
.cardForm:hover .cardItem {
  transform: scale(1.1);
}

.cardItemBg {
  width: 100%;
  height: auto;
}

.cardFormInner {
  border-radius: 16px;
  width: inherit;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.288);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 100px 10px 10px;
  border: 1px solid var(--border-dark-bluer);
  box-shadow: var(--main-box-shadow);
}
.cardTitle {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;

  margin-bottom: 5px;
  color: #fff;

    text-shadow: 0px 0px 10px var(--dark-color);
}


/* From Uiverse.io by flxderdev */
.button {
  position: relative;
  padding: 10px 15px;
  background: linear-gradient(90deg, var(--main-color), var(--second-color));
  border-radius: 5rem;
  transition: all 0.4s ease;
  color: var(--light2-color);
}
.buttonSection {
  padding: 8px 5px;
  font-size: 12px;
}

.button::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 0.9em;
  z-index: -10;
  filter: blur(0);
  transition: filter 0.4s ease;
}

.button:hover::before {
  background: linear-gradient(90deg, var(--main-color), var(--second-color));
  filter: blur(1.2em);
}
.button:active::before {
  filter: blur(0.2em);
}
.sectionTitle {
  font-weight: bold;
  color: var(--main-color);
}
.cardDescription {
  line-height: 1.6;
  /* letter-spacing: 1.5px; */
  font-size: 14px;
  color: var(--gray-color);
}
