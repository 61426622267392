.mySwiper {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  height: auto;
  width: 100%;
}

.SwiperSlide {
  background-position: center;
  background-size: cover;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  border: 2px solid var(--border-dark-bluer);
  border-radius: 18px;
  min-width: 180px;
}
.SwiperSlide > a {
  border-radius: 18px;
  background-color: transparent;
  pointer-events: auto;
}
/* .SwiperSlide a {
} */
.SwiperSlide-active {
  transform: scale(1.2);
  z-index: 1;
}

.containerImg {
  position: relative;
  /* background-color: var(--blue-color); */
  background: var(--main-color);
  background: linear-gradient(30deg, #0b2733 0%, #0f516d 63%, #159bd4 100%);
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 1px 15px var(--main-color);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.containerImg:hover {
  transform: scale(1.05);
}

.backgroundImg {
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.backgroundImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(0px -2px 5px #fff);
  /* filter: brightness(120%) contrast(90%) grayscale(20%); */
}

.SwiperSlide a {
  text-decoration: none;
}
.SwiperSlide a h4 {
  margin-top: 20px;
}
@media (max-width: 790px) {
  .SwiperSlide a h4 {
    font-size: 18px;
  }
}

.SwiperSlide a p {
  color: var(--dark-color);
}
