.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.bodyDelete{
  padding: 20px 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.bodyDelete h4 {
  color: var(--dark-color);
}