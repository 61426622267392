@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@1,700&family=Montserrat:wght@300&display=swap");

.blockCard {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
}

.card {
  position: relative;
  margin-top: 20px;
  width: 400px;
  height: 250px;
  background-color: var(--bg-dark-bluer);
  border: 1px solid var(--border-dark-bluer);
  box-shadow: var(--main-box-shadow);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  padding: 20px 30px;
  border-radius: 12px;
  overflow: hidden;
}

@media(max-width:779px){
  .card {
      margin-top: 15px;
  width: 300px;
  height: 200px;
  padding: 10px 20px;
  }
}

.cardContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.brand {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media(max-width:779px){
  .brand {
   font-size: 18px;
  }
}


.number {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
  letter-spacing: 4px;
}
@media(max-width:779px){
  .number {
     font-size: 20px;
  letter-spacing: 3px;
  }
}


.flexInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card span {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--main-color);
}

 .card span:nth-child(1),
.card span:nth-child(3) {
  height: 2px;
  width: 100%;
}

.card span:nth-child(2),
.card span:nth-child(4) {
  width: 2px;
  height: 100%;
}

.card span:nth-child(1) {
  top: 0;
  left: 0;
  animation: moveRight 4s linear infinite;
}

.card span:nth-child(2) {
  top: 0;
  right: 0;
  animation: moveDown 4s linear infinite;
}

.card span:nth-child(3) {
  bottom: 0;
  left: 0;
  animation: moveLeft 4s linear infinite;
}

.card span:nth-child(4) {
  top: 0;
  left: 0;
  animation: moveUp 4s linear infinite;
}

@keyframes moveRight {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes moveDown {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100%); }
}

@keyframes moveLeft {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

@keyframes moveUp {
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
}