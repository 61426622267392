/* .container {
  height: 600px;
} */

.icon {
  color: var(--main-color);
}

h3 span {
  color: var(--main-color);
}

.userBox {
  position: relative;
  overflow: hidden;
  padding-top: 10px;
}
.form {
  padding: 10px 70px;
}
@media (max-width: 970px) {
  .form {
    padding: 10px 0;
  }
}

/* .container form p {
  margin-bottom: 20px;
} */
.userBox input {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: var(--gray-color);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--gray-color);
  outline: none;
  background: transparent;
}

.userBox label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--gray-color);
  pointer-events: none;
  transition: 0.4s;
}

.userBox input:focus ~ label {
  top: -10px;
  left: 0;
  color: var(--main-color);
  font-size: 12px;
}
.userBox input:focus {
  border-bottom: 1px solid var(--second-color);
}

.animation {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 12px;
  left: 100%;
  background-color: var(--main-color);
  opacity: 50%;
  animation: rTol 0.4s linear forwards;
}

@keyframes rTol {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
    display: none;
  }
}

