.container {
  border: 1px solid #a3a3a3;
  border-radius: 20px;
  /* overflow: hidden; */
  background-color: var(--dark-color);
  color: var(--light-color);
}
.icon {
  padding: 0 5px;
  margin-top: -5px;
  background-color: var(--gray-color);
  color: #fff;
  font-size: 24px;
}
.containerW {
  padding: 0 5px 0 7px;
  font-size: 14px;
  color: var(--light-color);
}
@media (max-width: 977px) {
  .containerW {
    font-size: 12px;
  }

  .icon {
    font-size: 19px;
  }
}
.mo::after {
  display: none;
  color: var(--light-color) !important;
}
.dropdownMenu {
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.288);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid var(--main-color);
}
.dropdownItme {
  color: var(--dark-color);
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}
.dropdownItme svg {
  font-size: 20px;
  color: var(--main-color);
}
.dropdownItme:hover {
  background-color: var(--gray-color);
  color: var(--main-color);
}
