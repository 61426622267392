.container {
  display: flex;
  min-height: 100vh;
  position: relative;
  color: var(--dark-color);
  background-color: var(--light-color);
}

.container::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../dist/img/stars.png");
  opacity: 0.3;
  /* z-index: 0; */

}

.sidebar {
  width: 170px;
  padding: 70px 5px 5px;
  position: relative;
  background-color: var(--light2-color);
  box-shadow: -8px 0px 10px 0 var(--bg-dark-bluer);
  z-index:1 ;
}

.content {
  padding: 80px 20px 20px;
  width: calc(100% - 170px);
  z-index:0;
}

@media (max-width: 977px) {
  .content {
    padding: 70px 5px 5px;
    width: calc(100% - 60px);

  }
  .sidebar {
    padding: 60px 0 0;
    width: 60px;
  }
}
