.container {
  position: relative;
  min-height: calc(100vh - 60px);
}
.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: var(--light-color);
  top: 0;
  right: 0;
  padding: 10px;
  padding-right: 170px;
  z-index: 10;
  box-shadow: var(--main-box-shadow);
}
.sidebar {
  padding: 15px 10px;
  position: fixed;
  width: 150px;
  height: 100%;
  background-color: var(--light-color);
  top: 0;
  right: 0;
  z-index: 20;
}
.content {
  padding: 15px;
  margin-right: 150px;
  background-color: var(--light2-color);
  margin-top: 60px;
  z-index: -10;
}

@media (max-width: 977px) {
  .header {
    padding-right: 60px;
  }
  .sidebar {
    width: 60px;
    padding: 15px 5px;
  }
  .content {
    margin-right: 60px;
    padding: 5px;
  }
}
