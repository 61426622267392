.card {
  position: relative;
  overflow: hidden;
  box-shadow: var(--main-box-shadow);
}

.buttons {
  background-image: linear-gradient(#00000000, #0000003f, #0000008e, #000000);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  transition: 0.4s ease-in-out;
}

.card:hover .buttons {
  transition: 0.4s ease-in-out;
  bottom: 0;
}

.button {
  padding: 10px;
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  box-shadow: 0 7px 30px rgba(45, 128, 223, 0.75);
  transform: translateY(-5px);
}

.button:active {
  transform: scale(0.9);
  box-shadow: 0 2px 25px rgba(0, 162, 255, 0.2);
}
