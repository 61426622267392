.container {
  position: relative;
  min-height: 100vh;
  padding-top: 75px;
  z-index: 1;
}
.CountWatch{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 98, 128, 0);
  border-radius: 6px;
  z-index: 100 ;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../dist/img/stars.png");
  opacity: 0.5;
  z-index: -1;
}
