
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  /* min-width: 200px !important; */
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

  .watermark {
    font-size: 16px;
  }
  /* .watermark::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../dist/img/logo.png') no-repeat center center;
    background-size: cover;
    opacity: 0.3;
    z-index: -1; 
  } */
  .watermark .head {
    font-weight: 700;

    color: var(--main-color);
    margin-left: 5px;
  }