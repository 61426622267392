.bg-container{
  font-size: 14px;
  overflow: hidden;
  margin: 30px 0;
}
.mySwiper {
  height: auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 790px) {
  .mySwiper {
     padding-top:20px;
  padding-bottom:20px;
  }
}


.SwiperSlide {
  background-position: center;
  background-size: cover;
  width: 550px;

}
@media (max-width: 790px) {
  .SwiperSlide {
    width: 300px;
    padding: 5px;
  }
}

.SwiperSlide img {
  display: block;
  width: 100%;
  border-radius: 12px;
}

@media (max-width: 790px) {
  .SwiperSlide img{
    /* height: 300px; */
    width: 300px;
    padding: 5px;
  }
}

