.bodyScrollTop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  background-color: #ffffff18;
  border-radius: 50%;
  z-index: 1000;
}

.bodyScrollTop .icon {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  animation: upDown 0.7s linear infinite;
  color:var(--main-color);
}

@keyframes upDown {
  0% {
    margin-bottom: 5px;
  }
  50% {
    margin-bottom: 0px;
  }
  100% {
    margin-bottom: 5px;
  }
}
