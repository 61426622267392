.container {
  min-height: calc(100vh - 151px);
  padding-top: 70px;
  position: relative;
}



.cardForm {

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cardItem {
  width: calc(100% - 30px);
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: -90px;
  z-index: 10;
  box-shadow: var(--main-box-shadow);
  transition: 0.3s;
}

.cardForm:hover .cardItem {
  transform: scale(1.1);
}

.cardItemBg {
  width: 100%;
  height: auto;
}
.cardFormInner {
  border-radius: 16px;
  width: inherit;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: var(--main-box-shadow);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 100px 10px 10px;
}
.cardTitle {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 0px 0px 4px var(--main-color);
  margin-bottom: 5px;
}
.cardDescription p {
  font-size: 15px;
  color: #333;
  margin-bottom: 20px;
}
.button {
  width: 100%;
  height: 55px;
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  box-shadow: 0 7px 30px rgba(45, 128, 223, 0.75);
  transform: translateY(-5px);
}

.button:active {
  transform: scale(0.9);
  box-shadow: 0 2px 25px rgba(0, 162, 255, 0.2);
}
