.container {
  padding-top: 60px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0;
  color: var(--dark-color);
  min-height: 100vh;
  background-color: var(--light-color);
  transition: var(--main-transition);
}

.imgContainer {
  overflow: hidden;
  position: relative;
}
.imgContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #00000015;
  width: 100%;
  /* backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(5px); */
}

.imgContainer {
  background-image: url("../../dist/img/900-X-1600.jpg");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.formContainer {
  padding: 20px;
}

@media (max-width: 970px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
  }
  .imgContainer {
    background-image: url("../../dist/img/1600-X-900.jpg");
  }
}
