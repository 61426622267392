.navbar-toggler {
  padding: 6px 10px !important;
  border: 1px solid var(--main-color) !important;
  transition: var(--main-transition);
  color: var(--dark-color) !important;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  box-shadow: var(--main-box-shadow) !important;
  border-color: transparent !important;
  transition: var(--main-transition);
}

.navbar-nav .nav-link,
.navbar-nav .nav-link,
.nav-link,
.nav-link {
  border-radius: 8px;
  transition: var(--main-transition);
  border: 1px solid var(--main-color);
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.nav-link:focus,
.nav-link:hover {
  box-shadow: var(--main-box-shadow);
  transform: scale(1.03);
  transition: var(--main-transition);
  border-color: transparent;
}
@media (max-width: 977px) {
  .navbar-collapse {
    position: absolute;
    text-align: center;
    padding: 10px;
    width: 100%;
    top: 60px;
    right: 0;
    clip-path: circle(0% at 50% 0);
    background-color: rgba(0, 0, 0, 0.164);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: var(--main-transition);
    z-index: 100000;
  }
  .navbar-collapse .nav-item .nav-link:first-child {
    margin: 7px 0;
  }
  .navbar-collapse .nav-item .nav-link {
    background-color: var(--main-color);
    color: #eeeeee!important;
  }
  .navbar-collapse .nav-item .nav-link svg {
    color: #eeeeee !important;
  }
}
.navbar-collapse .nav-item .nav-link {
  color: var(--dark-color);
}
.navbar-collapse.show {
  clip-path: circle(99.7% at 50% 0);
}
