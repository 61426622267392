.container {
  width: 60vw;
}
@media (max-width: 970px) {
  .container {
    width: 65vw;
  }
}
@media (max-width: 770px) {
  .container {
    width: 85vw;
  }
}
.image,
.gifImage .gif {
  width: 150px;
  height: 150px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  background-image: linear-gradient(40deg, transparent, var(--main-color));
}

.gifImage {
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 10px;
}

.gifImage .gif {
  width: 100px;
  height: 100px;
}
.image img {
  height: 150px;
  width: 150px;
  border-radius: 12px;
  /* background-image: linear-gradient(40deg, transparent, var(--main-color)); */
}

.gifImage .gif img {
  height: 100px;
  width: 100px;
  border-radius: 12px;
}

@media (max-width: 770px) {
  .image {
    height: 100px;
    width: 100px;
  }
  .image img {
    height: 100px;
    width: 100px;
  }

  .gifImage .gif {
    width: 60px;
    height: 60px;
  }

  .gifImage .gif img {
    height: 60px;
    width: 60px;
  }
}
.customFileInput {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  font-size: 60px;
  cursor: pointer;
  color: #ffffff;
  z-index: 9;
  opacity: 0.8;
  filter: drop-shadow(5px 4px 8px #000000);
}
.image input[type="file"],
.imageOnly input[type="file"] {
  display: none;
}
.gifImage .gif input[type="file"] {
  display: none;
}
/* .pdf input[type="file"] {
  display: none;

} */
.imageOnly {
  width: 500px;
  height: auto;
  min-height: 250px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  background-image: linear-gradient(40deg, transparent, var(--main-color));
}
@media (max-width: 779px) {
  .imageOnly {
    width: 300px;
    min-height: 150px;
  }
}
/* width: "300px", height: "auto", minHeight: "150px" } */
