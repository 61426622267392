.container {
  min-height: 150px;
  width: 100%;
  background-color: var(--blue-color);
  color: white;
  z-index: 5;
  position: relative;
  align-items: center;
  justify-content: center;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  direction: ltr;
}
@media (max-width: 970px) {
  .footer {
    grid-template-columns: 1fr;
  }
}

.footer h6 {
  color: var(--gray-color);
  margin-bottom: 20px;
  padding-bottom: 5px;
  box-shadow: 0 1px 0 var(--gray-color);
}
.footer p {
  font-size: 13px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 22px;
  color: #fff;
  border-radius: 50%;
  outline: 2px solid #fff;
  transition-property: outline-offset, outline-color, background-color;
  transition-duration: 0.25s;
  cursor: pointer;
}
.icon a {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon svg {
  font-size: 20px;
}
.icon:hover {
  outline-offset: 4px;
}
.icon:hover svg {
  animation: shake 0.25s;
}

/* .icon:first-child:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d,
    #f56040,
    #f77737,
    #fcaf45,
    #ffdc80
  );
  outline-color: #a02d76;
} */
.icon:nth-child(1):hover {
  background-color: #1877f2;
  outline-color: #1877f2;
}
.icon:nth-child(2):hover {
  background-color: #ff0000;
  outline-color: #ff0000;
}
/* .icon:nth-child(4):hover {
  background-image: radial-gradient(
    circle at 90% 107%,
    #000000,
    #000000,
    #000000,
    #ff0050,
    #00f2ea,
    #000000,
    #000000,
    #000000
  );
  outline-color: #ffffff;
} */

.icon:nth-child(3):hover {
  background-image: radial-gradient(circle at 90% 107%, #25d366, #075e54);
  outline-color: #25d366;
}
.icon:nth-child(4):hover {
  background-color: #24a1de;
  outline-color: #24a1de;
}

@keyframes shake {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
}

.developerButton {
  background-color: #6969698f !important;
  font-weight: 500;
  color: #fff;
  border: 2px solid var(--gray-color);
}
.policies {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
.policies a{
  text-decoration: none;
  color:#fff;
}