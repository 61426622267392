.container {
  width: 400px;
}
@media (max-width: 970px) {
  .container {
    width: 300px;
  }
}
.container .progressPar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  gap: 30px;
}
.container .progressPar span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--dark-color);
  border: 2px solid var(--main-color);
  position: relative;
}
.container .progressPar span.passed {
  background-color: var(--main-color);
  border-color:  var(--main-color);
  color: var(--light-color);
}

.container .progressPar span:not(:last-child):before {
  content: "";
  position: absolute;
  height: 2px;
  width: 83px;
  background-color: var(--main-color);
  right: -83px;
}

@media (max-width: 970px) {
  .container .progressPar span:not(:last-child):before {
    width: 57px;
    background-color: var(--main-color);
    right: -57px;
  }
}

.succussMessage{
  font-size: 12px;
  color: var(--main-color);
}
.errorMessage{
  font-size: 12px;
  color: var(--danger-color);
}
.messageContainer{
  height: 40px;
}