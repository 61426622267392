.container {
  width: 100%;

  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: var(--main-box-shadow);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  padding: 10px;
  border: 1px solid var(--border-dark-bluer);
}

.classContainer {
  padding: 20px 0;
  margin: 20px 0;

  display: flex;

  justify-content: center;
  flex-direction: column;
  
}

.classTitle{
  padding: 5px 10px;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 5px;
}
.sectionTitle{
  padding: 5px;
  color:var(--danger-color);
  font-size: 28px;
}
.lecutreTitle{
  padding: 5px;
  color:var(--warning-color);
  font-size: 20px;
}