.container {
  padding: 60px 0;
  position: relative;
  text-align: center;
  background-image: linear-gradient(#00000000 10%, var(--main-color) 90%);
}

@media (max-width: 990px) {
  .container {
    padding: 50px 0;
  }
}

.effect {
  position: relative;
  display: inline-block;
  font-size: 40px;
  font-weight: bold;
  color: var(--dark-color);
  z-index: 2;
  &:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    color: var(--light-color);
    z-index: 1;
    animation: fill 6s infinite;
  }
}
@media (max-width: 990px) {
  .effect {
    font-size: 30px;
  }
}

@keyframes fill {
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.editorial1 {
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index: 5;
  bottom: 0;
  position: absolute;
  left: 0px;
  float: left;
}

.parallax1 > use {
  animation: move-forever1 10s linear infinite;
  opacity: 20%;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax2 > use {
  animation: move-forever2 8s linear infinite;
  opacity: 30%;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax3 > use {
  animation: move-forever3 6s linear infinite;
  opacity: 40%;
  &:nth-child(1) {
    animation-delay: -2s;
  }
}
.parallax4 > use {
  opacity: 60%;
  animation: move-forever4 4s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
}
@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
