.container {
  padding: 30px;
  width: inherit;
  height: inherit;
  background: rgba(255, 255, 255, 0.13);
  background-color: var(--bg-dark-bluer);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 12px;
  border: 1px solid var(  --border-dark-bluer);
  position: relative;
}

@media(max-width:990px){
  .container {
  padding: 10px 5px;
  }
}
.before::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url("../../../dist/img/moon.png");
  box-shadow:var(--sun-moon-box-shadow);
    background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  animation: move 50s infinite linear;

}

.container label {
  font-size: 12px;
  color: var(--gray-color);
  margin-bottom: 5px;
}

.container h5 {
  padding: 10px;
  box-shadow: inset 0 0 5px 1px var(--gray-color);
  border-radius: 12px;
}

.img {
  width: 100px;
  height: 100px;
  border: 1px solid var(--gray-color);
  padding: 5px;
}
@keyframes move {
  0% {
    top: 0;
    left: 20px;
  }
  10% {
    top: 20%;
    left: 50%;
  }
  25% {
    top: 10%;
    left: calc(100% - 180px);
  }
  40% {
    top: 50%;
    left: 20%;
  }
  50% {
    top: calc(100% - 180px);
    left: calc(100% - 180px);
  }
  60% {
    top: 70%;
    left: 10%;
  }
  75% {
    top: calc(100% - 180px);
    left: 20px;
  }
  90% {
    top: 20%;
    left: 80%;
  }
  100% {
    top: 0;
    left: 20px;
  }
}
@media(max-width:779px){
  .btn{
    font-size: 12px;
  }
}
