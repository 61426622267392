
.container {
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../dist/img/stars.png");
  opacity: 0.5;
}

.profile {
  min-height: calc(100vh - 100px);
  border-radius: 16px;
  overflow: hidden;
  border: 2px solid var(--border-dark-bluer);
  box-shadow: var(--main-box-shadow);
}
@media (max-width: 997px) {
  .profile {
    min-height: 60vh;
  }
}
.imgContainer {
  display: flex;
  align-items: end;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.288);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

}
/* @media (max-width: 997px) {
  .imgContainer {
    padding: 10px;
  }
} */
.imgContainer img {
  width: 100%;
  height: auto;
  filter:drop-shadow(0px -2px 10px var(--dark-color));
  
}

.infoContainer {
    display: flex;
  padding: 10px 50px 10px;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  background-color: var(--light-color);
  z-index: 1;
}

.infoContainer  h1{
  font-size: 45px;

  font-weight: 700;
}
.infoContainer  h3 {
  text-align: center;
  background-color: var(--main-color);
  font-weight: 700;
  border-radius: 12px;
  padding: 5px;
}
.infoContainer  h3 span {
  color:var(--dark-color);
}
.infoContainer  h2 span {
  color: var(--main-color);
  font-weight: 700;
}
.hiIcone {
  color: var(--main-color);
  animation: hi 2s ease-in-out infinite;
  transform-origin: bottom center; 
  margin-right: 5px;
}

.paragraph{
  font-size: 14px;
  letter-spacing: 1.6;
  /* line-height: 2; */
    line-height: 1.6;
  color :var(--dark-color)
}

@keyframes hi {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(30deg);
  }
  40% {
    transform: rotate(-20deg);
  }
  60% {
    transform: rotate(20deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
