@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

* {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400, 500, 700;
  font-style: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  --main-color: #29abe2;
  --second-color: #88d3f3;
  --success-color: #05b178;
  --blue-color: #0f032f;
  --gray-color: #6d717a;
  --danger-color: #f84616;
  --warning-color: #fad01d;
  --dark-color: #212529;
  --light-color: #fbfbff;
  --light2-color: #f0f2f8;
  --main-transition: 0.3s;
  --main-box-shadow: 0 5px 7px 0 #00000044;
  --bg-dark-bluer: #20202028;
  --border-dark-bluer: #2020203f;
  --header-box-shadow: inset 0px 0px 10px 4px #c5c5c5;
  --sun-moon-box-shadow: 0 0 15px 0 orange;
}

.dark {
  --gray-color: #d3d7db;
  --light-color: #212529;
  --dark-color: #f8f9fa;
  --light2-color: #2a2c2e;
  --main-box-shadow: 0 5px 6px 0 #b9b9b973;
  --bg-dark-bluer: #dfdfdf28;
  --border-dark-bluer: #e6e6e6c4;
  --header-box-shadow: inset 0px 0px 10px 4px #4b4b4b;
  --sun-moon-box-shadow: 0 0 15px 0 #fbfbff;
}

body {
  direction: rtl;
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
#root {
  scroll-behavior: smooth;
  background-color: var(--light2-color);
  color: var(--dark-color);
  /* transition: var(--main-transition); */
}

code {
  font-family: "Cairo", sans-serif;
}
.stop-scrolling {
  overflow: hidden;
}
/* الجدول */
.Mui-TableHeadCell-Content.MuiBox-root {
  justify-content: center;
}

body.dark .MuiTableContainer-root::-webkit-scrollbar {
  width: 12px; /* عرض شريط التمرير */
}

/* مسار شريط التمرير */
body.dark .MuiTableContainer-root::-webkit-scrollbar-track,
body.dark .MuiBox-root,
body.dark .MuiTypography-body1 {
  background: #1e1e1e; /* لون المسار */
}
/* body.dark .Mui-TableHeadCell-Content{
  background: #1e1e1e00; لون المسار
} */

/* مقبض شريط التمرير */
body.dark .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #888; /* لون المقبض */
  border-radius: 10px; /* نصف قطر الزوايا */
  border: 3px solid #1e1e1e; /* لون الحدود */
}

/* عند التحويم على المقبض */
body.dark .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* لون المقبض عند التحويم */
}
body.dark ul.MuiList-root.MuiList-padding.MuiMenu-list {
  background-color: #1e1e1e; /* لون المقبض عند التحويم */
}
body.dark
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 {
  background-color: #1e1e1e; /* لون المقبض عند التحويم */
}

.blur {
  background-color: var(--bg-dark-bluer) !important ;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

body.dark .form-control,
body.dark .form-select,
body.dark .form-select option {
  border-color: rgb(54, 54, 54) !important;
  color: var(--dark-color);
  background-color: rgba(78, 78, 78, 0.301) !important;
}
body.dark .input-group-text {
  background-color: rgba(37, 37, 37, 0.404);
  border-color: rgb(54, 54, 54);
  color: var(--dark-color);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  padding-left: calc(1.5em + 0.75rem) !important;
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

.form-floating > label {
  left: auto !important;
  right: 0 !important;
}
.form-control:focus,
.form-select:focus {
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 0.25rem #29aae259 !important;
}

.form-control.is-invalid:focus {
  border-color: #fe8686 !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 253, 121, 0) !important;
}
.invalid-feedback {
  font-size: 12px !important;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-size: 13px !important;
}
.input-group-text {
  height: 58px !important;
}
.input-group.has-validation {
  height: 77px !important;
}
.form-floating > .form-select {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* bootstrap overlay */
/* .dropdown-toggle-split::after.dropend.dropdown-toggle-split::after.dropup.dropdown-toggle-split::after {
  color:var(--light-color)
} */

.btn-primary {
  padding: 10px;
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
}
.btn-outline-primary {
  border-color: #29abe2;
  color: #29abe2;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  box-shadow: 0 7px 30px rgba(45, 128, 223, 0.75);
  transform: translateY(-5px);
}

.btn-primary:focus,
.btn-primary:active {
  transform: scale(0.9);
  box-shadow: 0 2px 25px rgba(0, 162, 255, 0.2);
}

body.dark .accordion-dark .accordion-button {
  background-color: #343a40;
  color: #ffffff;
}

body.dark .accordion-dark .accordion-button:not(.collapsed) {
  background-color: #495057;
  color: #ffffff;
}

body.dark .accordion-dark .accordion-body {
  background-color: #343a40;
  color: #ffffff;
}

body.dark .form-select.form-select-md {
  background-color: var(--light2-color) !important;
}
