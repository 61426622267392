.spinner {
  width: 60px;
  height: 60px;
}

.img {
  width: 60px;
  height: 60px;
  border: 1px solid var(--main-color);
}

.name {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}
.title {
  font-size: 14px;
  color: var(--gray-color);
}
@media (max-width: 977px) {
  .spinner {
    width: 30px;
    height: 30px;
  }
  .img {
    width: 45px;
    height: 45px;
  }
  .name {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
}
.containerImg {
  position: relative;
}

.Add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  font-size: 40px;
  cursor: pointer;

  color: var(--gray-color);
  z-index: 9;
  opacity: 0.4;
  filter: drop-shadow(5px 4px 8px #000000);
  transition: 0.3s;
}

.Add:hover {
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  font-size: 50px;
  transition: 0.3s;
}
