
.button a {
  color: var(--dark-color);
  font-size: 22px;
}
.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 45px;
  border-radius: 8px;
  background-size: 100px;
  color: var(--dark-color);
  border: none;
  border: 1px solid var(--main-color);
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.button:hover::after {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    var(--second-color) 10.8%,
    var(--main-color) 94.3%
  );
  transition: all 0.475s;
}

.button:hover a,
.button:hover svg {
  color: #fff;
  z-index: 2;
  transition: 0.3s;
}

@media (max-width: 977px) {
  .button {
    height: 35px;
    width: 40px;
  }
}

.button svg {
  width: 100% !important;
  height: 100% !important;
  padding: 8px;
}
