.container {
  padding-top: 80px;
  position: relative;
  min-height: 100vh;
  z-index: 0;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../dist/img/stars.png");
  opacity: 0.5;
  z-index: -1;
}

.content {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
}

.container h4 {
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}

.container ul li,
.container p {
  font-size: 14px;
  padding-top: 5px;
}
