
#carouselExampleIndicators{
max-height: 100vh;
overflow: hidden;
}
.carousel-item {
  transition: transform 1.5s ease-in-out;
}

.slider_img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-fit: contain; */
}

@media(min-width:986px){
.slider_img{
  height: 100vh;
  /* object-fit: contain; */
}
}